import { cn } from '@/src/shared/lib/tailwindUtils'

import { LocalizedLink } from '../LocalizedLink'

const Logo = ({ className }: { className?: string }) => {
  return (
    <LocalizedLink href="/" className={cn('-mx-2 inline-flex p-2', className)}>
      <svg className="h-5 w-[7.875rem]" viewBox="0 0 126 19" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.7328 9.99996C18.7328 8.14746 18.1835 6.33656 17.1543 4.79626C16.1251 3.25596 14.6623 2.05544 12.9508 1.34652C11.2393 0.637599 9.35602 0.452113 7.53912 0.813518C5.72221 1.17492 4.05328 2.06699 2.74336 3.3769C1.43344 4.68682 0.541378 6.35576 0.179973 8.17266C-0.181432 9.98957 0.00405413 11.8728 0.712976 13.5843C1.4219 15.2958 2.62241 16.7587 4.16271 17.7878C5.70301 18.817 7.51391 19.3664 9.36641 19.3664V18.9301C9.36641 17.1019 7.76432 15.6884 6.24419 14.6727C5.32001 14.0552 4.5997 13.1775 4.17435 12.1506C3.749 11.1237 3.63771 9.99372 3.85455 8.90358C4.07139 7.81344 4.60663 6.81208 5.39258 6.02613C6.17853 5.24018 7.17989 4.70494 8.27004 4.48809C9.36018 4.27125 10.4901 4.38254 11.517 4.8079C12.5439 5.23325 13.4216 5.95356 14.0391 6.87774C15.0549 8.39786 16.4683 9.99996 18.2966 9.99996H18.7328Z"
          fill="#3D70FF"
        />
        <path
          d="M9.36621 9.99996C9.36621 11.8525 9.91554 13.6634 10.9447 15.2037C11.9739 16.744 13.4368 17.9445 15.1483 18.6534C16.8597 19.3623 18.743 19.5478 20.5599 19.1864C22.3768 18.825 24.0458 17.9329 25.3557 16.623C26.6656 15.3131 27.5577 13.6442 27.9191 11.8273C28.2805 10.0104 28.095 8.12708 27.3861 6.41559C26.6771 4.7041 25.4766 3.24127 23.9363 2.21207C22.396 1.18288 20.5851 0.633545 18.7326 0.633545V1.06979C18.7326 2.89803 20.3347 4.31151 21.8548 5.32723C22.779 5.94474 23.4993 6.82244 23.9247 7.84934C24.35 8.87623 24.4613 10.0062 24.2445 11.0963C24.0276 12.1865 23.4924 13.1878 22.7065 13.9738C21.9205 14.7597 20.9191 15.295 19.829 15.5118C18.7389 15.7287 17.6089 15.6174 16.582 15.192C15.5551 14.7667 14.6774 14.0464 14.0599 13.1222C13.0442 11.6021 11.6307 9.99996 9.80246 9.99996H9.36621Z"
          fill="#44CDAC"
        />
        <path
          d="M48.0576 9.08551C48.0576 10.3227 47.8058 11.4176 47.3022 12.3701C46.7985 13.3117 46.0978 14.0508 45.2 14.5873C44.3022 15.1128 43.2621 15.3756 42.0796 15.3756C40.9081 15.3756 39.8734 15.1128 38.9757 14.5873C38.0888 14.0508 37.3936 13.3117 36.8899 12.3701C36.3972 11.4285 36.1509 10.3391 36.1509 9.10194C36.1509 7.86473 36.4027 6.77533 36.9063 5.83374C37.41 4.8812 38.1052 4.14216 38.9921 3.61662C39.8899 3.08013 40.9245 2.81189 42.096 2.81189C43.2785 2.81189 44.3132 3.08013 45.2 3.61662C46.0978 4.14216 46.7985 4.87573 47.3022 5.81732C47.8058 6.75891 48.0576 7.84831 48.0576 9.08551ZM45.2493 9.08551C45.2493 8.30815 45.1234 7.64575 44.8715 7.09832C44.6197 6.53993 44.2584 6.11293 43.7876 5.81732C43.3278 5.5217 42.7694 5.37389 42.1125 5.37389C41.4555 5.37389 40.8917 5.5217 40.4209 5.81732C39.9501 6.11293 39.5888 6.53993 39.337 7.09832C39.0851 7.64575 38.9592 8.30815 38.9592 9.08551C38.9592 9.86287 39.0851 10.5307 39.337 11.0891C39.5888 11.6475 39.9501 12.0745 40.4209 12.3701C40.8917 12.6657 41.4555 12.8136 42.1125 12.8136C42.7694 12.8136 43.3278 12.6657 43.7876 12.3701C44.2584 12.0636 44.6197 11.6311 44.8715 11.0727C45.1234 10.5143 45.2493 9.85192 45.2493 9.08551Z"
          className="fill-text-primary"
        />
        <path
          d="M49.4351 19.12V6.96693H51.8165L51.9478 7.96874C52.1778 7.58554 52.5336 7.27897 53.0153 7.04905C53.4971 6.81913 54.0281 6.70416 54.6084 6.70416C55.3748 6.70416 56.0481 6.87387 56.6284 7.21328C57.2087 7.55269 57.6631 8.03991 57.9915 8.67494C58.32 9.29901 58.4842 10.0545 58.4842 10.9413C58.4842 11.8063 58.331 12.5782 58.0244 13.257C57.7288 13.9249 57.2908 14.4504 56.7105 14.8336C56.1412 15.2168 55.446 15.4084 54.6248 15.4084C54.0555 15.4084 53.5299 15.3099 53.0482 15.1128C52.5665 14.9157 52.2051 14.6639 51.9643 14.3573V19.12H49.4351ZM51.9807 11.0727C51.9807 11.4778 52.0628 11.8391 52.227 12.1566C52.4022 12.4632 52.6376 12.7041 52.9332 12.8792C53.2398 13.0435 53.5902 13.1256 53.9843 13.1256C54.3894 13.1256 54.7343 13.038 55.019 12.8628C55.3146 12.6876 55.539 12.4468 55.6923 12.1402C55.8565 11.8336 55.9387 11.4778 55.9387 11.0727C55.9387 10.6676 55.8565 10.3118 55.6923 10.0052C55.539 9.69864 55.3146 9.45777 55.019 9.28259C54.7343 9.10741 54.3894 9.01982 53.9843 9.01982C53.5902 9.01982 53.2398 9.10741 52.9332 9.28259C52.6376 9.44682 52.4022 9.68222 52.227 9.98878C52.0628 10.2953 51.9807 10.6567 51.9807 11.0727Z"
          className="fill-text-primary"
        />
        <path
          d="M63.5861 15.392C62.7649 15.392 62.0313 15.2059 61.3854 14.8336C60.7503 14.4613 60.2467 13.9522 59.8744 13.3063C59.5131 12.6493 59.3325 11.8993 59.3325 11.0563C59.3325 10.2023 59.5076 9.4523 59.858 8.80632C60.2193 8.1494 60.7175 7.63481 61.3525 7.26255C61.9875 6.89029 62.7156 6.70416 63.5368 6.70416C64.4017 6.70416 65.1462 6.88482 65.7703 7.24613C66.3944 7.59649 66.8761 8.10013 67.2156 8.75705C67.555 9.40303 67.7247 10.1749 67.7247 11.0727V11.7132L60.6627 11.7461L60.6956 10.1859H65.2119C65.2119 9.74791 65.0641 9.40303 64.7685 9.15121C64.4729 8.88844 64.0678 8.75705 63.5532 8.75705C63.1262 8.75705 62.7758 8.83917 62.5021 9.0034C62.2394 9.16763 62.0423 9.42492 61.9109 9.77528C61.7795 10.1147 61.7138 10.5526 61.7138 11.0891C61.7138 11.8555 61.8671 12.4249 62.1737 12.7971C62.4912 13.1584 62.9784 13.3391 63.6353 13.3391C64.1171 13.3391 64.5167 13.257 64.8342 13.0928C65.1517 12.9285 65.3543 12.6986 65.4419 12.403H67.7575C67.6152 13.3117 67.1663 14.0398 66.4108 14.5873C65.6554 15.1237 64.7138 15.392 63.5861 15.392Z"
          className="fill-text-primary"
        />
        <path
          d="M71.5873 15.1785H69.0582V6.96693H71.4395L71.6037 7.82094C71.8555 7.47058 72.2004 7.19686 72.6384 6.99978C73.0873 6.8027 73.58 6.70416 74.1165 6.70416C75.0909 6.70416 75.8464 7.00525 76.3829 7.60743C76.9303 8.19867 77.204 9.03077 77.204 10.1037V15.1785H74.6749V10.7114C74.6749 10.1968 74.5435 9.78623 74.2807 9.47967C74.0179 9.16215 73.6676 9.0034 73.2296 9.0034C72.726 9.0034 72.3263 9.15668 72.0307 9.46324C71.7351 9.75886 71.5873 10.164 71.5873 10.6786V15.1785Z"
          className="fill-text-primary"
        />
        <path
          d="M90.3991 9.08551C90.3991 10.3227 90.1473 11.4176 89.6436 12.3701C89.14 13.3117 88.4393 14.0508 87.5415 14.5873C86.6437 15.1128 85.6035 15.3756 84.4211 15.3756C83.2495 15.3756 82.2149 15.1128 81.3171 14.5873C80.4302 14.0508 79.735 13.3117 79.2314 12.3701C78.7387 11.4285 78.4923 10.3391 78.4923 9.10194C78.4923 7.86473 78.7441 6.77533 79.2478 5.83374C79.7514 4.8812 80.4467 4.14216 81.3335 3.61662C82.2313 3.08013 83.266 2.81189 84.4375 2.81189C85.62 2.81189 86.6546 3.08013 87.5415 3.61662C88.4393 4.14216 89.14 4.87573 89.6436 5.81732C90.1473 6.75891 90.3991 7.84831 90.3991 9.08551ZM87.5907 9.08551C87.5907 8.30815 87.4648 7.64575 87.213 7.09832C86.9612 6.53993 86.5999 6.11293 86.1291 5.81732C85.6692 5.5217 85.1108 5.37389 84.4539 5.37389C83.797 5.37389 83.2331 5.5217 82.7623 5.81732C82.2915 6.11293 81.9302 6.53993 81.6784 7.09832C81.4266 7.64575 81.3007 8.30815 81.3007 9.08551C81.3007 9.86287 81.4266 10.5307 81.6784 11.0891C81.9302 11.6475 82.2915 12.0745 82.7623 12.3701C83.2331 12.6657 83.797 12.8136 84.4539 12.8136C85.1108 12.8136 85.6692 12.6657 86.1291 12.3701C86.5999 12.0636 86.9612 11.6311 87.213 11.0727C87.4648 10.5143 87.5907 9.85192 87.5907 9.08551Z"
          className="fill-text-primary"
        />
        <path
          d="M97.4261 6.96693V9.34828H96.6214C95.8988 9.34828 95.3294 9.52346 94.9134 9.87382C94.5083 10.2242 94.3057 10.8045 94.3057 11.6147V15.1785H91.7766V6.98336H94.1579L94.355 8.74063H94.1251C94.2345 8.19319 94.4809 7.74429 94.8641 7.39393C95.2583 7.03263 95.7893 6.85197 96.4571 6.85197C96.6104 6.85197 96.7637 6.86292 96.917 6.88482C97.0812 6.89577 97.2509 6.92314 97.4261 6.96693Z"
          className="fill-text-primary"
        />
        <path
          d="M98.5408 15.1785V6.96693H101.07V15.1785H98.5408ZM99.789 5.68593C99.3839 5.68593 99.039 5.54907 98.7543 5.27535C98.4806 4.99069 98.3437 4.6458 98.3437 4.2407C98.3437 3.8356 98.4806 3.49619 98.7543 3.22247C99.039 2.94875 99.3839 2.81189 99.789 2.81189C100.183 2.81189 100.517 2.94875 100.791 3.22247C101.075 3.49619 101.218 3.8356 101.218 4.2407C101.218 4.6458 101.075 4.99069 100.791 5.27535C100.517 5.54907 100.183 5.68593 99.789 5.68593Z"
          className="fill-text-primary"
        />
        <path
          d="M102.393 10.9249C102.393 10.0928 102.557 9.35923 102.885 8.72421C103.225 8.08918 103.69 7.59101 104.281 7.2297C104.883 6.8684 105.568 6.68774 106.334 6.68774C106.98 6.68774 107.533 6.81913 107.993 7.0819C108.464 7.34467 108.787 7.68955 108.962 8.11655L108.715 8.28078L108.913 6.96693H111.294V14.6529C111.294 15.6274 111.113 16.465 110.752 17.1657C110.391 17.8773 109.876 18.4193 109.208 18.7916C108.54 19.1748 107.736 19.3664 106.794 19.3664C105.535 19.3664 104.511 19.027 103.723 18.3481C102.935 17.6803 102.48 16.7661 102.36 15.6055H104.922C104.944 16.0653 105.119 16.4212 105.447 16.673C105.776 16.9358 106.219 17.0671 106.778 17.0671C107.391 17.0671 107.872 16.8974 108.223 16.558C108.584 16.2186 108.765 15.7588 108.765 15.1785V13.4376L109.044 13.6511C108.869 14.0781 108.529 14.423 108.026 14.6858C107.522 14.9486 106.942 15.0799 106.285 15.0799C105.518 15.0799 104.84 14.9048 104.248 14.5544C103.668 14.204 103.214 13.7168 102.885 13.0928C102.557 12.4687 102.393 11.7461 102.393 10.9249ZM104.938 10.8592C104.938 11.2534 105.02 11.6037 105.184 11.9103C105.349 12.2059 105.573 12.4413 105.858 12.6165C106.142 12.7917 106.471 12.8792 106.843 12.8792C107.226 12.8792 107.56 12.7971 107.845 12.6329C108.141 12.4577 108.365 12.2223 108.518 11.9267C108.683 11.6201 108.765 11.2643 108.765 10.8592C108.765 10.4541 108.683 10.1092 108.518 9.82455C108.365 9.52894 108.141 9.29901 107.845 9.13478C107.56 8.97055 107.221 8.88844 106.827 8.88844C106.455 8.88844 106.126 8.97055 105.841 9.13478C105.557 9.29901 105.332 9.52894 105.168 9.82455C105.015 10.1202 104.938 10.4651 104.938 10.8592Z"
          className="fill-text-primary"
        />
        <path
          d="M113.032 15.1785V6.96693H115.561V15.1785H113.032ZM114.28 5.68593C113.875 5.68593 113.53 5.54907 113.245 5.27535C112.971 4.99069 112.835 4.6458 112.835 4.2407C112.835 3.8356 112.971 3.49619 113.245 3.22247C113.53 2.94875 113.875 2.81189 114.28 2.81189C114.674 2.81189 115.008 2.94875 115.282 3.22247C115.566 3.49619 115.709 3.8356 115.709 4.2407C115.709 4.6458 115.566 4.99069 115.282 5.27535C115.008 5.54907 114.674 5.68593 114.28 5.68593Z"
          className="fill-text-primary"
        />
        <path
          d="M119.823 15.1785H117.294V6.96693H119.675L119.84 7.82094C120.091 7.47058 120.436 7.19686 120.874 6.99978C121.323 6.8027 121.816 6.70416 122.352 6.70416C123.327 6.70416 124.082 7.00525 124.619 7.60743C125.166 8.19867 125.44 9.03077 125.44 10.1037V15.1785H122.911V10.7114C122.911 10.1968 122.779 9.78623 122.516 9.47967C122.254 9.16215 121.903 9.0034 121.465 9.0034C120.962 9.0034 120.562 9.15668 120.267 9.46324C119.971 9.75886 119.823 10.164 119.823 10.6786V15.1785Z"
          className="fill-text-primary"
        />
      </svg>
    </LocalizedLink>
  )
}

export default Logo
