export const socials = [
  {
    key: 'twitter',
    link: 'https://x.com/OpenOriginAI',
  },
  {
    key: 'telegram',
    link: 'https://t.me/OpenOriginAI',
  },
  {
    key: 'linkedin',
    link: 'https://www.linkedin.com/company/openorigin-ai',
  },
]
