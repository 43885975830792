import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/entities/analytics/ui/InitAnalytics/InitAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/app/src/shared/ui/Toaster/Toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/src/widgets/header/ui/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-hubot-sans\",\"src\":[{\"path\":\"../../../../public/fonts/HubotSans-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"HubotSansFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/src/app/fonts/index.ts\",\"import\":\"Barlow\",\"arguments\":[{\"variable\":\"--font-barlow\",\"weight\":[\"400\",\"500\",\"700\"],\"style\":\"normal\",\"subsets\":[\"latin\"]}],\"variableName\":\"BarlowFont\"}");
